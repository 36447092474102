// Import necessary React and react-leaflet components along with Leaflet itself
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet'; // Leaflet for map functionality
import 'leaflet/dist/leaflet.css'; // Leaflet's default styles
import './TennesseeMap.css'; // Custom CSS for styling the map container
import icon4 from './icon4.png'; // Custom icon for the marker

// ChangeView component: A functional component to change the map view dynamically
function ChangeView({ center, zoom }) {
  const map = useMap(); // useMap is a hook to access the map instance
  map.setView(center, zoom); // Changes the view to the new center and zoom level
  return null; // Renders nothing, as its purpose is purely functional
}

// Defining a custom icon using Leaflet's API
const customIcon = new L.Icon({
  iconUrl: icon4, // The URL to the custom icon image
  iconSize: [50, 50], // Size of the icon in pixels
  iconAnchor: [25, 25], // Point of the icon which will correspond to marker's location
});

// TennesseeMap component: Main component to render the map
const TennesseeMap = () => {
  const position = [35.5175, -86.5804]; // Latitude and Longitude to center on Tennessee
  const zoom = 8; // Initial zoom level

  // Coordinates for a marker in Lebanon, TN
  const markerPositionLebanon = [36.2081, -86.2911];

  // Rendering the map container and its child components
  return (
    <div className="tennessee-map-container">
      <MapContainer center={position} zoom={zoom} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {/* Dynamic view changer */}
        <ChangeView center={position} zoom={zoom} />
        {/* Marker for Lebanon, TN */}
        <Marker 
          position={markerPositionLebanon} 
          icon={customIcon}
          eventHandlers={{
            click: () => {
              window.location.href = 'https://lebanon.artificialgrassrecycle.com';
            },
          }}
        >
          <Popup>Lebanon, TN</Popup>
        </Marker>
        {/* Additional markers can be added here */}
      </MapContainer>
      <footer>
        Choose a different State:
        <select name="states" id="state-selector">
          <option value="california">California</option>
          <option value="florida">Florida</option>
          <option value="new-york">New York</option>
        </select>
      </footer>
    </div>
  );
};

export default TennesseeMap;
